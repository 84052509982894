$(document).ready(function() {
    var altura = $(".fundo-carousel").height();
    $(".fundos-carousel").height(altura);

    banner = {
        n_itens: $(".bolinha").length,
        pos: 0,
        interval : null,

        init: function() {
            $(".arrow-left").on("click",function() {
                banner.esquerda(true);
            });
            $(".arrow-right").on("click",function() {
                banner.direita(true);
            }); 

            $(".bolinha").on("click",function() {
            	banner.pos = $(this).index();
                banner.move(banner.pos);
            });
            
            banner.interval = window.setInterval(function(){
                banner.direita(false);
            },5000);
            
        },
        esquerda: function(clear) {
            if(clear)
                window.clearInterval(banner.interval);
            
            if(banner.pos > 0) {           	
                banner.pos--; 
                banner.move(banner.pos); 
            }else {
            	banner.pos = banner.n_itens - 1;
            	banner.move(banner.pos); 
            }
        },

        direita: function(clear) {
            if(clear)
                window.clearInterval(banner.interval);
            
            if(banner.pos < banner.n_itens - 1) {           	
                banner.pos++;                
                banner.move(banner.pos);
            }else {
            	banner.pos = 0;
            	banner.move(banner.pos);
            }                                       
        },

        move: function() {
            $(".bolinha").removeClass('bolinha-ativa');
            $(".bolinha").eq(banner.pos).addClass('bolinha-ativa');

            $(".fundo-carousel").stop().fadeOut(400);
            $(".fundo-carousel").eq(banner.pos).stop().fadeIn(400);           
        }
    };
    banner.init();
});