$(document).ready(function () {
    /*plugin fancybox*/
    if ($(".fancybox").length > 0) {
        $(".fancybox").fancybox();
    }

    /*mask tel*/
    if (typeof $("input[type=tel]").mask == 'function') {
        var options = {onKeyPress: function (cep) {
                var mask;
                var e = cep.replace(/\D/g, "");
                if (e.length > 10) {
                    mask = "(00)00000-0000";
                } else {
                    mask = "(00)0000-00000";
                }
                $("input[type=tel]").mask(mask, this);
            }};
        $("input[type=tel]").mask('(00)0000-00000', options);
    }

    /*Validação form*/
    if ($(".valida-form").length > 0) {
        $(".valida-form").WP8FormValidation({
            breakLine: '<br />',
            onInputError: function (obj) {
                obj.addClass("marca-erro");
            },
            onInputOk: function (obj) {
                obj.removeClass("marca-erro");
            },
            onFormSubmitError: function (mensagens) {
                $(".erro").find('span').html(mensagens);
                $(".erro").addClass('show-warning');
            }
        });
    }

    /* FORM AJAX */
    if ($('.ajax').length > 0) {
        $('.ajax').on('submit', function (e) {
            e.preventDefault();
            var _this = $(this);
            var button = $(this).find('[type=submit]');
            var texto_botao = button.text();
            $.ajax({
                method: "POST",
                url: $(this).prop("action"),
                data: $(this).serialize(),
                beforeSend: function () {
                    button.text("Aguarde");
                    button.prop('disabled', 'disabled');
                    _this.prop('disabled', 'disabled');
                    _this.find('.warning').removeClass('show-warning');
                },
                complete: function () {
                    button.text(texto_botao);
                    button.removeAttr('disabled');
                    _this.removeAttr('disabled');
                },
                success: function () {
                    $(".ok").addClass('show-warning');
                },
                error: function (jqXHR) {
                    var data = jqXHR.responseJSON;
                    var erros = '';
                    var s = '';
                    for (var propertyName in data) {
                        _this.find('[name=' + propertyName + ']').addClass("marca-erro")
                        erros += s + data[propertyName];
                        s = '<br/>';
                    }
                    $(".erro").find('span').html(erros);
                    $(".erro").addClass('show-warning');
                }
            });
        });
    }

});